body {
  padding: 0;
  font-family: "Roboto", sans-serif;
  margin: 0;
  background-color: #eee;
}

#root {
  height: 100%;
  display: flex;
}
